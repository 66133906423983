import React from "react";
import {FileZipOutlined} from "@ant-design/icons";
import {Button, FloatButton} from "antd";
import style from "./style.module.css";

const RenderBtn = ({customizations, showModal}) => {
	const {widgetButtonTextColor, type} = customizations;
	if (type === 'rectangle') {
		return (
			<Button
				type="primary"
				onClick={showModal}
				icon={<FileZipOutlined style={{ color: widgetButtonTextColor || "#fff1f0" }} />}
				className={style["fixed-btn"]}
				style={{ color: widgetButtonTextColor }}
			>
				<span style={{ alignSelf: "center" }}>Free Resume Review</span>
			</Button>
		);
	}

	if (customizations?.type === 'circle') {
		return (
			<FloatButton
				type="primary"
				icon={
					<FileZipOutlined
						style={{
							fontSize: "25px",
							color: widgetButtonTextColor || "#fff1f0",
						}}
					/>
				}
				onClick={showModal}
				className={style["float-btn"]}
				style={{color: widgetButtonTextColor}}
			>
				Open Modal
			</FloatButton>
		);
	}
	return null
}

export default RenderBtn;
